import React, { Suspense } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { MainLoader } from "./globals/preloaders";
// import { MoralisProvider } from "react-moralis/lib/context";

const Landing = React.lazy(() => import("./App"));
const StageOne = React.lazy(() =>
  import("./components/onboarding/stages/onboardinStageOne")
);
const StageTwo = React.lazy(() =>
  import("./components/onboarding/stages/onboardinStageTwo")
);
const StageThree = React.lazy(() =>
  import("./components/onboarding/stages/onboardinStageThree")
);
const OnboardingSuccess = React.lazy(() =>
  import("./components/onboarding/onboardingsuccess.jsx")
);
const AssetTracking = React.lazy(() =>
  import("./components/assetstracking/assettracking.jsx")
);


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Suspense fallback={<MainLoader />}>
      <Router>
        <Switch>
          {/* <MoralisProvider serverUrl="https://rsxlelatafbn.usemoralis.com:2053/server"> */}
          <Route path="/" exact component={Landing} />
          <Route path="/onboarding/stage_one" exact component={StageOne} />
          <Route path="/onboarding/stage_two" exact component={StageTwo} />
          <Route path="/onboarding/stage_three" exact component={StageThree} />
          <Route path="/onboarding/success" exact component={OnboardingSuccess} />
          <Route path="/assetstracking/dashboard" exact component={AssetTracking} />
          {/* <Route path="/onboarding/stage_five" exact component={StageFive} /> */}
          {/* </MoralisProvider> */}
        </Switch>
      </Router>
    </Suspense>{" "}
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
